import { Controller } from "@hotwired/stimulus"
import Client from 'shopify-buy/index.unoptimized.umd'
import * as Handlebars from 'handlebars';

const cartTemplate = `
  <div class="modal-header">
    <span class="modal-title fs-5">Your Cart</span>
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
  </div>
  <div class="modal-body">
    {{^lines.edges}}
    <p>Your cart is empty.</p>
    {{/lines.edges}}
    {{#lines.edges}}
    <div class="d-flex align-items-top mb-4" data-controller="cart-line" data-cart-line-line-id-value="{{node.id}}" data-cart-line-year-value="{{node.attributes.0.value}}">
      <div class="pe-3" style="width: 25%">
        <img src="{{node.merchandise.product.featuredImage.url}}" class="img-fluid img-thumbnail"/>
      </div>
      <div class="d-flex flex-column me-2 w-100">
       <span class="fw-bold mb-0">{{node.merchandise.product.title}} ({{node.attributes.0.value}})</span>
       <small class="mb-2 text-muted">Mounting option: {{node.merchandise.title}}</small>

        <div class="d-flex align-items-center">
          <label class="me-2 text-muted"><small>Qty</small></label>
          <input data-cart-line-target="quantity" data-cart-line-target="quantity" type="number" value="{{node.quantity}}" style="width:55px" size="2" class="form-control form-control-sm" aria-label="Quantity" aria-describedby="quantity-label">
          <a class="d-block ms-2 text-muted me-auto" role="button" data-action="click->cart-line#remove"><small>Remove</small></a>
        </div>
      </div>
      <div class="" style="ms-auto">
        <span class="ms-auto">\${{node.cost.totalAmount.amount}}0</span>
      </div>
    </div>
    {{/lines.edges}}
    <div class="d-flex flex-column ms-auto text-end mt-3">
      <span class="fw-bold fs-4">\${{cost.subtotalAmount.amount}}0</span>
      <small class="text-muted">Taxes & shipping calculated at checkout</small>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-link" data-bs-dismiss="modal" aria-label="Back">Back</button>
    {{#if totalQuantity}}
    <a href="{{checkoutUrl}}" role="button" class="btn btn-primary">Continue To Checkout</a>
    {{/if}}
  </div>
  `

export default class extends Controller {
  static targets = ["modal"]
  static values = { accessToken: String, memberEmail: String, memberId: { type: String, default: 'guest' }, referralCampaign: String }
  //static outlets = ["cartIcon"]

  connect() {
    super.connect();
    this.memberIdValue = _.isEmpty(this.memberIdValue) ? 'guest' : this.memberIdValue;

    this.client = Client.buildClient({
      domain: 'all-roads-taken.myshopify.com',
      storefrontAccessToken: this.accessTokenValue
    });

    let cart = localStorage.getItem('cart');

    if (cart){
      cart = JSON.parse(cart);
      this.fetchCart(cart.id);
    }
    else {
      this.createCart();
    }
  }

  refreshCart(cart) {
    localStorage.setItem('cart', JSON.stringify(cart));
    this.cart = cart;

    // Outlets not working for some reason - revist later
    //this.cartIconOutlets.forEach((icon) => icon.setQuantity(cart.totalQuantity));
    // workaround below
    document.querySelectorAll("#cartIcon").forEach((icon) => {
      this.application.getControllerForElementAndIdentifier(icon, 'cart-icon').setQuantity(cart.totalQuantity);
    });

    // handle modal not being defined
    if (this.modalTarget) {
      this.modalTarget.innerHTML = this.renderCartModal(cart);
    }

    console.log(`Cart: refreshed`);
  };

  renderCartModal(cart){
    cart.stringCart = JSON.stringify(cart, null, 2);
    return Handlebars.compile(cartTemplate)(cart);
  };

  createCart(){
    let input = { attributes: [
      { key: 'member_id', value: this.memberIdValue },
      { key: 'referral_campaign', value: this.referralCampaignValue }
    ]};

    if (!_.isEmpty(this.memberEmailValue)) {
      console.log("Adding email to cart");
      console.log(this.memberEmailValue);
      input.buyerIdentity = { email: this.memberEmailValue };
    }

    const cartCreateMutation = this.client.graphQLClient.mutation('cartCreate', [], (root) => {
      root.add('cartCreate', { args: { input: input }}, (cartCreate) => {
        cartCreate.add('cart', (cart) => {
          cart.add('id');
          cart.add('checkoutUrl');
          cart.add('attributes', (attribute) => {
            attribute.add('key');
            attribute.add('value');
          });
        });
        cartCreate.add('userErrors', (error) => {
          error.add('code');
          error.add('field');
          error.add('message');
        });
      });
    });

    console.log(cartCreateMutation.toString());

    this.client.graphQLClient.send(cartCreateMutation).then(({model, data, errors}) => {
      // Do something with the products
      if (errors) {
        var err = new Error(errors.map(e => e.message).join(','));
        newrelic.noticeError(err);
      } else if (data?.cartCreate?.cart) {
        console.log("Cart created");
        this.refreshCart(data?.cartCreate?.cart);
      }
    }).catch((error) => {
      console.error('Error:', error);
      newrelic.noticeError(error, { message: 'Cart creation failed', memberEmail: this.memberEmailValue });
    });
  };


  fetchCart(id){
    const query = this.client.graphQLClient.query((root) => {
      root.add('cart', {args: {id: id}}, (cart) => {
        this.setCartResponseAttributes(cart);
      });
    });

    //console.log(query.toString());

    this.client.graphQLClient.send(query).then(({model, data, errors}) => {
      // Do something with the products
      console.log("Cart fetched");
      console.log(data.cart);
      if (errors) {
        var err = new Error(errors.map(e => e.message).join(','));
        newrelic.noticeError(err);
      } else if (data?.cart) {
        this.refreshCart(data.cart);
      }
      else {
        this.createCart();
      }
    }).catch((error) => {
      console.error('Error:', error);
      newrelic.noticeError(error, { message: 'Cart fetch failed', memberEmail: this.memberEmailValue });
    });
  };

  // Handles
  handleAddToCart({ detail: { content } }){
    const addLine = this.client.graphQLClient.mutation('cartLinesAdd', [], (root) => {
      root.add('cartLinesAdd', {args: {cartId: this.cart.id, lines: { attributes: [{ key: 'year', value: content.year}], merchandiseId: content.productId }}}, (cartLinesAdd) => {
        cartLinesAdd.add('cart', (cart) => {
          this.setCartResponseAttributes(cart);
        });
      });
    });
    //console.log(addLine.toString());

    this.client.graphQLClient.send(addLine).then(({model, data, errors}) => {
      // Do something with the products
      if (errors) {
        var err = new Error(errors.map(e => e.message).join(','));
        newrelic.noticeError(err);
      } else if (data?.cartLinesAdd?.cart) {
        console.log("Line added");
        this.refreshCart(data?.cartLinesAdd?.cart);
        // TODO refactor to use Stimulus event
        document.querySelector("#cartIcon").click();
      }
    }).catch((error) => {
      console.error('Error:', error);
      newrelic.noticeError(error, { message: 'Add to cart failed', memberEmail: this.memberEmailValue });
    });
  };

  handleUpdateQuantity({ detail: { content } }){
    var line = { id: content.id, quantity: Number(content.quantity) };
    if(line.quantity > 0) {
      line.attributes = [{ key: 'year', value: content.year}];
    }
    const updateLine = this.client.graphQLClient.mutation('cartLinesUpdate', [], (root) => {
      root.add('cartLinesUpdate', {args: {cartId: this.cart.id, lines: line} }, (cartLinesUpdate) => {
        cartLinesUpdate.add('cart', (cart) => {
          this.setCartResponseAttributes(cart);
        });
      });
    });
    console.log(updateLine.toString());

    this.client.graphQLClient.send(updateLine).then(({model, data, errors}) => {
      // Do something with the products
      console.log("Line updated");
      if (errors) {
        var err = new Error(errors.map(e => e.message).join(','));
        newrelic.noticeError(err);
      } else if (data?.cartLinesUpdate?.cart) {
        this.refreshCart(data?.cartLinesUpdate?.cart);
      }
    }).catch((error) => {
      console.error('Error:', error);
      newrelic.noticeError(error, { message: 'Update cart failed', memberEmail: this.memberEmailValue });
    });
  };

  // Handles setting up the response attributes for cart requests
  setCartResponseAttributes(cart) {
    cart.add('id');
    cart.add('checkoutUrl');
    cart.add('attributes', (attribute) => {
      attribute.add('key');
      attribute.add('value');
    });
    cart.add('totalQuantity');
    cart.add('cost', (cost) => {
      cost.add('subtotalAmount', (money) => {
        money.add('amount');
      });
    });
    cart.addConnection('lines', {args: {first: 50}}, (line) => {
      line.add('id');
      line.add('quantity');
      line.add('attributes', (attribute) => {
        attribute.add('key');
        attribute.add('value');
      });
      line.add('cost', (cost) => {
        cost.add('totalAmount', (money) => {
          money.add('amount');
        });
      });
      line.add('merchandise', (merch) => {
        merch.addInlineFragmentOn('ProductVariant', (pv) => {
          pv.add('id');
          pv.add('title');
          pv.add('product', (product) => {
            product.add('title');
            product.add('featuredImage', (image) => {
              image.add('url');
            });
          });
        });
      });
    });
  };
}
