import { Controller } from "@hotwired/stimulus"
import L from "leaflet"
import { GestureHandling } from "leaflet-gesture-handling"
import 'leaflet.markercluster'
import 'leaflet.fullscreen'
import * as Handlebars from 'handlebars';

const mediaPopupTemplate = `
  <div class="d-flex w-100 flex-column">
    <img src="{{media.url}}" style="width: 200px; object-fit: contain" class="rounded mb-1">
    <span class="fw-bolder mb-1">{{media.caption}}</span>
    <small class="text-muted">{{media.date}}</small>
    <small class="text-muted">{{media.location}}</small>
    <small class="text-muted">{{media.lat}}, {{media.lng}}</small>
  </div>
`

export default class extends Controller {
  static targets = ["container"]
  static values = { media: String, location: Object, geometry: String, gestureHandling: Boolean }

  connect() {
    this.showMap();
  };

  showMap() {
    L.Map.addInitHook("addHandler", "gestureHandling", GestureHandling);
    var map = L.map(this.containerTarget, {
      gestureHandling: true,
      fullscreenControl: true,
      fullscreenControlOptions: { position: 'topleft' }
    });

    // `fullscreenchange` Event that's fired when entering or exiting fullscreen.
    map.on('fullscreenchange', function () {
      if (map.isFullscreen()) {
        map.gestureHandling.disable();
        alert('Entered fullscreen');
      } else {
        map.gestureHandling.enable();
        alert('Exited fullscreen');
      }
    });

    L.control.scale({'imperial': true, 'metric': false}).addTo(map);

    var outdoors = L.tileLayer('https://api.mapbox.com/styles/v1/allroadstaken/clzbxv6l3002l01p66v9eb66s/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiYWxscm9hZHN0YWtlbiIsImEiOiJjbDk3azZyZnYwY29mM3BsOW1mdjE2ZDRkIn0.Dlg5NsF2Y08HVZ6vVn4qQQ', {
      maxZoom: 19,
      minZoom: 1,
      attribution: '&copy; <a href="https://www.mapbox.com/about/maps/">Mapbox</a> &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    })

    var satelite = L.tileLayer('https://api.mapbox.com/styles/v1/allroadstaken/clzbzmnad00r601pa2srzfnrj/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiYWxscm9hZHN0YWtlbiIsImEiOiJjbDk3azZyZnYwY29mM3BsOW1mdjE2ZDRkIn0.Dlg5NsF2Y08HVZ6vVn4qQQ', {
      maxZoom: 19,
      minZoom: 1,
      attribution: '&copy; <a href="https://www.mapbox.com/about/maps/">Mapbox</a> &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    })

    map.addLayer(outdoors);

    var baseMaps = {
      "Outdoors": outdoors,
      "Satelite": satelite
    };

    var overlayMaps = {}

    L.control.layers(baseMaps, overlayMaps).addTo(map)

    var bounds = [];

    try {
      if (!_.isEmpty(this.locationValue)) {
        console.log(`Location: ${this.locationValue}`);
        let coordinates = this.locationValue;
        let coordinateArray = [coordinates.latitude, coordinates.longitude];
        let locationMarker = L.marker(coordinateArray);
        locationMarker.addTo(map)
          .bindPopup(`Log location: ${coordinates.latitude}, ${coordinates.longitude}`);
        bounds.push(coordinateArray);
      }
    } catch (error) {
      console.error(`Error: ${error}`);
    }

    if (!_.isEmpty(this.mediaValue)) {
      console.log(`Media: ${this.mediaValue}`);
      let jsonMedia = JSON.parse(this.mediaValue);

      var markers = L.markerClusterGroup({ maxClusterRadius: 50 });
      jsonMedia.forEach(function (media) {
        markers.addLayer(L.marker([media.lat, media.lng])
          .bindPopup(Handlebars.compile(mediaPopupTemplate)({media: media}), {autoPan: true}));
        bounds.push([media.lat, media.lng]);
      });
      map.addLayer(markers);
    }

    if (this.geometryValue) {
      let jsonGeometry = JSON.parse(this.geometryValue);
      L.geoJSON(jsonGeometry).addTo(map);
      bounds.push(L.geoJSON(jsonGeometry).getBounds());
    }

    if (bounds.length > 0) {
      map.fitBounds(bounds);
    }
  };
}

// <div data-controller="map" data-map-target="container" data-map-zoom-value="5" data-map-center-lat="39.8283" data-map-center-lng="-98.5795" data-map-id="trails-map" class="map mb-3" style="height: 500px;"></div>

