import { Controller } from "@hotwired/stimulus"

const LEAVING_PAGE_MESSAGE = "You have attempted to leave this page and you are uploading files. If you leave, you will need to reupload these files.  Are you sure you want to exit this page?"

export default class extends Controller {
  static values = { autoSubmit: Boolean, insertTarget: { type: String, default: "beforeend" } }

  connect() {
    this.element.addEventListener("change", this.validate.bind(this));

    addEventListener("direct-upload:initialize", function(event) {
      this.setUploading("true")
      const { target, detail } = event
      const { id, file } = detail
      // insert email inside a the target
      this.element.insertAdjacentHTML(this.insertTargetValue,
        `<div id="direct-upload-${id}" class="mt-1 direct-upload direct-upload--pending">
          <div id="direct-upload-progress-${id}" class="direct-upload__progress" style="width: 0%"></div>
          <span class="direct-upload__filename">${file.name}</span>
        </div>`
      )
      //target.previousElementSibling.querySelector(`.direct-upload__filename`).textContent = file.name
    }.bind(this))

    addEventListener("direct-upload:start", event => {
      const { id } = event.detail
      const element = document.getElementById(`direct-upload-${id}`)
      element.classList.remove("direct-upload--pending")
    })

    addEventListener("direct-upload:progress", event => {
      const { id, progress } = event.detail
      const progressElement = document.getElementById(`direct-upload-progress-${id}`)
      progressElement.style.width = `${progress}%`
    })

    addEventListener("direct-upload:error", event => {
      event.preventDefault()
      const { id, error } = event.detail
      const element = document.getElementById(`direct-upload-${id}`)
      element.classList.add("direct-upload--error")
      element.setAttribute("title", error)
    })

    addEventListener("direct-upload:end", function(event) {
      this.setUploading("false")
      const { id } = event.detail
      const element = document.getElementById(`direct-upload-${id}`)
      element.classList.add("direct-upload--complete")
    }.bind(this))

    addEventListener("turbo:before-visit", this.leavingPage.bind(this));
  }

  validate(event) {
    let tooBig = _.some(event.target.files, (file) => {
      return (file.size / 1024.0 / 1024.0) > 15
    });
    if (tooBig) {
      this.element.insertAdjacentHTML('beforebegin',
        '<div class="mt-1 alert alert-danger"><strong>Photo/s must be under 15mb.</strong><br/>Please select an alternate photo or use an app to resize.</div>');
    } else {
      console.log(`Auto submit value: ${this.autoSubmitValue}`);
      if (!!this.autoSubmitValue) {
        event.target.form.requestSubmit();
      }
    }
  }

  leavingPage(event) {
    if (this.isUploadingFiles()) {
      if (!window.confirm(LEAVING_PAGE_MESSAGE)) {
        event.preventDefault()
      }
    }
  }

  setUploading(changed) {
    this.data.set("uploading", changed)
  }

  isUploadingFiles() {
    return this.data.get("uploading") == "true";
  }
}
